
export const QuickLinks = {
      course_catalog:
          {
            title: "Course Catalog",
            path: "https://techtree.iiitd.edu.in/",
          },

      prospective_students:
          {
            title: "Prospective Students",
            path: "/prospective-students",
          },

      research_labs:
          {
            title: "Labs",
            path: "/research/labs",
          },

      phd_admissions:
          {
            title: "PhD Admissions",
            path: "/admissions/phd",
          },

      events:
          {
              title: "Events",
              path: "/news-and-events/events",
          }
}
