import React, { useEffect, useState } from "react"
// import "../Nav/Nav.css"
import "./Footer.css"
import IIITDLogo from "../../assets/images/iiitd_footer_color.svg"
import IIITDLogoSmall from "../../assets/images/iiitd_color.svg"

export default function Footer() {
  const [windowWidth, setWindowWidth] = useState(null)

  useEffect(() => {
    setWindowWidth(window.innerWidth)
  }, [])

  return (
    <footer>
      <div className="footer-div">
        <a href="https://www.iiitd.ac.in">
          <img
            className="iiitd-logo"
            src={windowWidth > 800 ? IIITDLogo : IIITDLogoSmall}
            alt="IIITD Logo"
          />
        </a>
      </div>
    </footer>
  )
}
